<script>
import {getFromServer} from "@/scripts/serverCalls";
import {generateYears, constructQuery} from "@/lib/utils";
import ListItem from "@/components/ListItem.vue";

export default {
  name: "ListView",
  components: {ListItem},
  data() {
    return {
      debounceTimer: null,
      showFilters: false,
      isFetching: false,
      sort: {
        items: [
          { text: "Preis aufsteigend", value: "price_asc" },
          { text: "Preis absteigend", value: "price_desc" },
          { text: "Vertragsjahr aufsteigend", value: "price_date_asc" },
          { text: "Vertragsjahr absteigend", value: "price_date_desc" },
        ],
        selected: "",
      },
      filter: {
        search: "",
        portal: {
          items: [
            { text: "Alle", value: null },
            { text: "Immoscout24", value: "Immoscout" },
            { text: "Immowelt", value: "Immowelt" },
            { text: "Immonet", value: "Immonet" },
            { text: "Kleinanzeigen Immobilien", value: "Kleinanzeigen" },
            { text: "Immobilie1", value: "Immobilie1" },
            { text: "Willhaben", value: "Willhaben" },
            { text: "properti", value: "properti" },
          ],
          selected: "",
        },
        profession: {
          items: [
            { text: "Alle", value: null },
            { text: "Bauträger", value: "Bauträger" },
            { text: "Immobilienmakler", value: "Makler" },
            { text: "Immobilienverwalter", value: "Verwalter" },
          ],
          selected: "",
        },
        objectsPerMonth: {
          items: [
            { text: "Alle", value: null },
            { text: "0-15", value: "0-15" },
            { text: "15-30", value: "15-30" },
            { text: "30-60", value: "30-60" },
            { text: "60-100", value: "60-100" },
            { text: "100+", value: "100+" },
          ],
          selected: "",
        },
        year: {
          items: generateYears(),
          selected: ""
        },
        contract_additions: [
          { text: "Alle", value: null },
          { text: "Ja", value: "true" },
          { text: "Nein", value: "false" },
        ],
        selected: "",
      },
      data: {
        statistics: {
          min: 0,
          avg: 0,
          max: 0
        },
        prices: [],
        filteredPrices: []
      }
    }
  },
  methods: {
    toggleFilters() {
      this.showFilters = !this.showFilters;
    },
    async fetch() {
      if (this.isFetching) return;
      this.isFetching = true;

      await getFromServer(`map/prices/all${constructQuery("?", this.filter)}`, async (res) => {
        this.data = res.data;
        this.filterPrices();
        this.sortItems();
      });

      this.isFetching = false;
    },
    filterPrices() {
      if (this.filter.search.trim() === "") {
        this.data.filteredPrices = this.data.prices;
        return;
      }

      const search = this.filter.search.trim().toLowerCase();

      this.data.filteredPrices = this.data.prices.filter(price => {
        if (price.zip && typeof price.zip === 'string') return price.zip.toLowerCase().includes(search);

        return false;
      });
    },
    sortItems() {
      if (!this.data || !this.data.filteredPrices) return;

      switch (this.sort.selected) {
        case "price_asc":
          this.data.filteredPrices.sort((a, b) => a.price_per_month - b.price_per_month);
          break;

        case "price_desc":
          this.data.filteredPrices.sort((a, b) => b.price_per_month - a.price_per_month);
          break;

        case "price_date_asc":
          this.data.filteredPrices.sort((a, b) => new Date(a.price_date) - new Date(b.price_date));
          break;

        case "price_date_desc":
          this.data.filteredPrices.sort((a, b) => new Date(b.price_date) - new Date(a.price_date));
          break;

        default:
          break;
      }
    },
    debouncedFetch() {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        this.fetch();
      }, 700);
    },
  },
  async mounted() {
    await this.fetch();
  },
}
</script>

<template>
  <div class="list-view">
    <header class="app navigation" style="flex: 0; background-color: var(--msag-color); padding: 10px; position: sticky; top:0;">
      <v-container>
        <v-row justify="space-between" style="align-items: center; padding-bottom: 5px;">
          <div style="margin-right:10px;">
            <v-row justify="space-between" style="align-items: center;">
              <v-col style="flex:0;">
                <div style="background-color:white; padding: 10px; border-radius: 10px; height: 50px; width: 50px;">
                  <img src="@/assets/Maklersprechstunde-Logo.png" alt="Square Image" style="width: 30px; height: 30px;"/>
                </div>
              </v-col>
              <v-col style="flex:1; padding: 0px; margin: 0px;">
                <h1 style="color: white; text-align: left;">Portalpreise.com</h1>
              </v-col>

              <a style="margin: 5px 10px;" class="addButton" href="https://maklersprechstunde.com/portalpreise-neuer-eintrag" target="_blank" rel="noopener noreferrer">
                Preis&nbsp;Hinzufügen&nbsp;+
              </a>

              <router-link to="/">
                <a class="addButton" style="margin: 5px 10px;">
                  Zur Karte
                </a>
              </router-link>

              <div v-if="$vuetify.breakpoint.mdAndDown" style="justify-self: flex-end;">
                <v-row justify="flex-end" style="align-items: center; margin: 5px 10px;">
                  <a @click="toggleFilters" class="addButton d-flex">
                    <v-icon color="var(--msag-blue)" size="16px">{{ showFilters ? 'mdi-filter' : 'mdi-filter-outline' }}</v-icon>
                    Filter
                  </a>
                </v-row>
              </div>
            </v-row>
          </div>
          <div style="flex:1; max-width:700px; padding:0px 10px; padding-left:10px;">
            <v-row v-if="$vuetify.breakpoint.mdAndDown && showFilters" justify="space-between" style="align-items: center; width: 100%; margin-top: 0px;" dark>
              <v-col style="min-width:100px;">
                <v-select
                    label="Portal"
                    :items="filter.portal.items"
                    v-model="filter.portal.selected"
                    @change="fetch()"
                    :disabled="isFetching"
                    hide-details
                    dark
                    :menu-props="{ maxHeight: '500px' }"
                />
              </v-col>
              <v-col style="min-width:100px;">
                <v-select
                    label="Objekte"
                    :items="filter.objectsPerMonth.items"
                    v-model="filter.objectsPerMonth.selected"
                    @change="fetch()"
                    :disabled="isFetching"
                    hide-details
                    dark
                />
              </v-col>
              <v-col style="min-width:155px;">
                <v-select
                    label="Vertragszusätze"
                    :items="filter.contract_additions"
                    v-model="filter.contract_additions.selected"
                    @change="fetch()"
                    :disabled="isFetching"
                    hide-details
                    dark
                />
              </v-col>
              <v-col style="min-width:100px;">
                <v-select
                    label="Tätigkeit"
                    :items="filter.profession.items"
                    v-model="filter.profession.selected"
                    @change="fetch()"
                    :disabled="isFetching"
                    hide-details
                    dark
                />
              </v-col>
              <v-col style="min-width:100px;">
                <v-select
                    label="Vertragsjahr"
                    :items="filter.year.items"
                    v-model="filter.year.selected"
                    @change="fetch()"
                    :disabled="isFetching"
                    hide-details
                    dark
                    :menu-props="{ maxHeight: '500px' }"
                />
              </v-col>
            </v-row>
          </div>
        </v-row>
      </v-container>
    </header>

    <div class="list-container" style="padding-bottom: 64px;">
      <h1 style="text-align: center; margin-top: 32px; font-size: 48px;">Portalpreise im Überblick</h1>

      <!-- Sort & Search -->
      <v-container style="margin-top: 64px;">
        <v-row style="column-gap: 16px;">
          <v-col :cols="$vuetify.breakpoint.smAndDown ? '0' : '3'"/>
          <v-col :cols="$vuetify.breakpoint.smAndDown ? '12' : '8'">
            <v-row style="column-gap: 16px;">
              <v-col v-if="!$vuetify.breakpoint.smAndDown" />

              <v-col class="list-item-no-gutters" style="max-width: 200px;">
                <v-select
                    label="Sortieren nach"
                    :items="sort.items"
                    v-model="sort.selected"
                    @change="sortItems()"
                    :disabled="isFetching"
                    hide-details
                    :menu-props="{ maxHeight: '500px' }"
                />
              </v-col>
              <v-col class="list-item-no-gutters" :style="$vuetify.breakpoint.smAndDown ? '' : 'max-width: 400px;'">
                <v-text-field
                    prepend-icon="mdi-magnify"
                    label="Nach Postleitzahl suchen"
                    v-model="filter.search"
                    @input="debouncedFetch()"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>

      <!-- List -->
      <v-container style="margin-top: 16px;">
        <v-row style="column-gap: 16px;">
          <v-col
              :cols="$vuetify.breakpoint.smAndDown ? '12' : '3'"
              style="align-self: start;"
          >

            <!-- Heat Meter & Statistics -->
            <v-row
                class="list-item"
                style="margin-bottom: 16px;"
            >
              <v-col>
                <h2
                    class="text-center"
                    style="margin-bottom: 16px;"
                >
                  Portalpreis / Monat
                </h2>
                <div class="heat-meter-container-condensed">
                  <div style="display: flex; justify-content: space-between; color: black;">
                    <div>{{ Math.round(data.statistics.min).toLocaleString('de-DE') }}€</div>
                    <div>{{ Math.round(data.statistics.avg).toLocaleString('de-DE') }}€</div>
                    <div>{{ Math.round(data.statistics.max).toLocaleString('de-DE') }}€</div>
                  </div>
                  <div class="metric-flipped"/>
                </div>
                <div style="display: flex; justify-content: space-between;">
                  <div class="heat-meter-label">Min</div>
                  <div class="heat-meter-label">Durchschnitt</div>
                  <div class="heat-meter-label">Max</div>
                </div>
              </v-col>
            </v-row>

            <!-- Desktop Filters -->
            <v-row
                v-if="!$vuetify.breakpoint.mdAndDown"
                class="list-item"
            >
              <v-col>
                <v-container>
                  <v-row>
                    <v-col>
                      <h2 style="text-align: center; margin-bottom: 8px;">Preise Filtern</h2>
                    </v-col>
                  </v-row>
                  <v-row style="min-width:100px;">
                    <v-col>
                      <v-select
                          label="Portal"
                          :items="filter.portal.items"
                          v-model="filter.portal.selected"
                          @change="fetch()"
                          :disabled="isFetching"
                          hide-details
                          :menu-props="{ maxHeight: '500px' }"
                      />
                    </v-col>
                  </v-row>
                  <v-row style="min-width:100px;">
                    <v-col>
                      <v-select
                          label="Objekte"
                          :items="filter.objectsPerMonth.items"
                          v-model="filter.objectsPerMonth.selected"
                          @change="fetch()"
                          :disabled="isFetching"
                          hide-details
                      />
                    </v-col>
                  </v-row>
                  <v-row style="min-width:155px;">
                    <v-col>
                      <v-select
                          label="Vertragszusätze"
                          :items="filter.contract_additions"
                          v-model="filter.contract_additions.selected"
                          @change="fetch"
                          :disabled="isFetching"
                          hide-details
                      />
                    </v-col>
                  </v-row>
                  <v-row style="min-width:100px;">
                    <v-col>
                      <v-select
                          label="Tätigkeit"
                          :items="filter.profession.items"
                          v-model="filter.profession.selected"
                          @change="fetch()"
                          :disabled="isFetching"
                          hide-details
                      />
                    </v-col>
                  </v-row>
                  <v-row style="min-width:100px;">
                    <v-col>
                      <v-select
                          label="Vertragsjahr"
                          :items="filter.year.items"
                          v-model="filter.year.selected"
                          @change="fetch()"
                          :disabled="isFetching"
                          hide-details
                          :menu-props="{ maxHeight: '500px' }"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-col>

          <!-- Price Items -->
          <v-col :cols="$vuetify.breakpoint.smAndDown ? '12' : '8'">
            <v-row
                v-for="(item, index) in data.filteredPrices"
                :key="item"
                class="list-item"
                :style="index !== 0 ? 'margin-top: 26px;' : ''"
            >
              <ListItem :item="item"/>
            </v-row>
          </v-col>

        </v-row>
      </v-container>
    </div>
    <footer style="position:fixed; flex: 0; bottom: 0; left: 0; right: 0; z-index: 1000;">
      <LegendBox class="legend-container" style="margin-left: 0;"/>

      <div style="margin-top: auto; background-color: #FFF; color:#666;">
        <v-container>
          <p style="margin-bottom: 0; font-size: 14px;">Ⓒ Makler Service AG {{ new Date().getFullYear() }} | <a href="/impressum/">Impressum</a> | <a href="
/datenschutzerklaerung/">Datenschutz</a></p>
        </v-container>
      </div>
    </footer>
  </div>
</template>

<style scoped lang="scss">
.list-view {
  background-color: #f6f6f6;
  min-height: 100vh
}

.list-container {
  max-width: 104rem;
  margin: auto;
  padding: 8px;
}

.list-item {
  background-color: #fff;
  padding: 18px;
  border: #dfdfdf 1px solid;
  border-radius: 10px;
}

.list-item-no-gutters {
  background-color: #fff;
  border: #dfdfdf 1px solid;
  border-radius: 10px;
}

.heat-meter-label {
  margin-top: 4px;
  font-size: 11px;
  color: dimgray;
  font-weight: 600;
}
</style>