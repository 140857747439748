<template>
  <v-app class="app">
    <v-main>
      <LoginView v-if="!isAuthorized" @loginAttempt="login"/>
      <!-- Renders views via routing, check ./router/index.js -->
      <router-view v-else class="app main" />
    </v-main>
  </v-app>
</template>

<script>
import LoginView from './views/LoginView.vue';

export default {
  name: "App",

  components: {
    LoginView
  },
  data: () => ({
    isAuthorized: true,
  }),
  methods: {
    login(e){
      if(e) this.isAuthorized = true;
      else window.globalToast.error("Wrong Password");
    }
  }
};
</script>
<style lang="scss">
@import "./appStyling.css";

.map-loadingscreen {
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.415);
  backdrop-filter: blur(2px);
}
.centeredcontent {
  text-align: center;
}

a.addButton {
  background-color: white;
  border-radius: 10px;
  text-align: center;
  color: var(--msag-color);
  text-decoration: none;
  flex:0;
  padding:5px 10px;
  font-size: 14px;
}
a:hover {
  text-decoration: none !important;
}

a.addButtonBig {
  background-color: var(--msag-color);
  border-radius: 10px;
  text-align: center;
  color: white !important;
  text-decoration: none;
  flex:0;
  padding: 5px 10px;
  font-size: 14px;
}

a:hover.addButton {
  background-color: #EEE;
}

.legend-container {
  z-index: 100;
  max-width: 350px;
}

.nav-btn {
  width: 50px;
  height: 50px;
  padding: 4px 4px;
  cursor: pointer;
  border-radius: 10px;
  background-color: #FFF;
  user-select: none;
  font-size: 32px;
  text-align: center;
  display: flex;
  justify-content: center;
  justify-items: center;
  border-style: solid;
  border-width: 2px;
  border-color: #CCC;
}
.nav-btn:hover {
  background-color: #EEE;
}

.nav-btn-correction {
  margin-top: -6px;
}

.heat-meter-container {
  position: relative;
  width: 100%;
  height: 64px;
}

.heat-meter-container-condensed {
  position: relative;
  width: 100%;
  height: 48px;
}

.metric {
  position: absolute;
  bottom: 0px;
  border-radius: 20px;
  width: 100%;
  height: 20px;
  background: linear-gradient(
          to left,
          #57BC89,
          #9DBE6B,
          #FDD666,
          #F3A86C,
          #E57C72,
  );
}

.metric-flipped {
  position: absolute;
  bottom: 0px;
  border-radius: 20px;
  width: 100%;
  height: 20px;
  background: linear-gradient(
          to right,
          #57BC89,
          #9DBE6B,
          #FDD666,
          #F3A86C,
          #E57C72,
  );
}
</style>
