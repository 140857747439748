export function generateYears() {
    const currentYear = new Date().getFullYear();
    const years = [];
    years.push("Alle");
    for (let i = 0; i < 7; i++) {
        years.push((currentYear - i).toString());
    }
    years.push("vor " + (currentYear - 6).toString());
    return years;
}

export function constructQuery(start = "?", filter) {
    let queryParts = [];
    if (filter.portal.selected) queryParts.push(`portal=${encodeURIComponent(filter.portal.selected)}`);
    if (filter.profession.selected && filter.profession.selected !== "Alle") queryParts.push(`profession=${encodeURIComponent(filter.profession.selected)}`);
    if (filter.objectsPerMonth.selected) queryParts.push(`objectspermonth=${encodeURIComponent(filter.objectsPerMonth.selected)}`);
    if (filter.year.selected && filter.year.selected !== "Alle") queryParts.push(`year=${encodeURIComponent(filter.year.selected)}`);
    if (filter.contract_additions.selected) queryParts.push(`contract_additions=${encodeURIComponent(filter.contract_additions.selected)}`);
    if (filter.search && typeof filter.search === 'string' && filter.search.trim()) queryParts.push(`search=${encodeURIComponent(filter.search.trim())}`);
    return queryParts.length > 0 ? `${start}${queryParts.join("&")}` : "";
}