<script>
export default {
  name: "ListItem",
  props: {
    item: Object
  },
  methods: {
    formatDateToDDMMYYYY(dateString) {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const year = date.getFullYear();

      if(year == "1970") {
        return "Unbekannt";
      }
      return `${day}.${month}.${year}`;
    }
  }
}
</script>

<template>
  <v-container>
    <v-row>
      <v-col>
        <v-row>
          <h2>{{ item.portal }}</h2>
        </v-row>
        <v-row>
          <span class="item-sub-key">Vertrag von:</span>
          <span class="item-sub-val">{{ formatDateToDDMMYYYY(item.price_date) }}</span>
        </v-row>
        <v-row>
          <span class="item-sub-key">Region:</span><span class="item-sub-val">{{ item.zip }}</span>
        </v-row>
        <v-row>
          <span class="item-sub-key">Tätigkeit:</span><span class="item-sub-val">{{ item.profession }}</span>
        </v-row>
      </v-col>

      <v-col class="d-flex flex-column align-center">
        <v-row class="flex flex-column justify-center">
          <h3 class="text-center">{{ item.objects_per_month === 1000 ? "Unbegrenzt" : item.objects_per_month }}</h3>
        </v-row>
        <v-row>
          <span class="item-sub-val text-center">Max. Objekte gleichzeitig online</span>
        </v-row>
      </v-col>

      <v-col class="d-flex flex-column align-center">
        <v-row class="flex flex-column justify-center">
          <h3 class="text-center">
            <v-icon :color="item.contract_additions ? 'green' : 'red'" size="48px">{{ item.contract_additions ? 'mdi-check' : 'mdi-close' }}</v-icon>
          </h3>
        </v-row>
        <v-row class="flex flex-column justify-center">
          <span class="item-sub-val text-center" style="hyphens: auto;">Vertrags&shy;zusätze</span>
        </v-row>
      </v-col>

      <v-col class="d-flex flex-column align-center">
        <v-row class="flex flex-column justify-center">
          <h3 class="text-center item-price">{{ item.price_per_month }}€</h3>
        </v-row>
        <v-row class="flex flex-column justify-center">
          <span class="item-sub-val text-center">Preis / Monat</span>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped lang="scss">
.item-price {
  font-size: 32px;
}
.item-sub-key {
  font-size: 12px;
  color: grey;
  font-weight: 500;
  margin-right: 4px;
}
.item-sub-val {
  font-size: 12px;
  color: dimgray;
  font-weight: 700;
}
</style>